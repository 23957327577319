





























import { Component, Prop, Mixins, Vue } from 'vue-property-decorator';

@Component({})
export default class PaytableSection extends Vue {
    private paytableRTP: string = window.rtp !== 95 ? `./assets/${window.lang}/paytable/texts/game_rules_rule8_${window.rtp}.png` : `./assets/${window.lang}/paytable/texts/game_rules_rule8.png`;

    get lang (): string {
        return window.lang;
    }
}
