import { render, staticRenderFns } from "./FeaturesSection.vue?vue&type=template&id=3c80140b&scoped=true&"
import script from "./FeaturesSection.vue?vue&type=script&lang=ts&"
export * from "./FeaturesSection.vue?vue&type=script&lang=ts&"
import style0 from "./FeaturesSection.vue?vue&type=style&index=0&id=3c80140b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c80140b",
  null
  
)

export default component.exports