














import { Component, Prop, Mixins, Vue } from 'vue-property-decorator';

@Component({})
export default class PaytableSecondSection extends Vue {
    get lang (): string {
        return window.lang;
    }
}
