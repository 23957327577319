




import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class LoadingScreen extends Vue {
    @Prop({ default: '' })
    icon!: string;

    @Prop({ default: '' })
    ext!: string;

    iconSource = `./assets/common/icons/${this.icon}.${this.ext}`;
}

