






import { Component, Prop, Mixins, Vue } from 'vue-property-decorator';

@Component({})
export default class WinLinesSection extends Vue {}
