













import { Component, Prop, Vue } from 'vue-property-decorator';
import { IPaytable } from '../../store/modules/common/contracts/paytable';

@Component({})
export default class PaytableSymbolDesktop extends Vue {
    @Prop({ default: '' })
    symbolName!: string;

    @Prop({ default: null })
    symbolPays?: IPaytable[];

    get stake (): number {
        return this.$store.state.stake.value;
    }
}
