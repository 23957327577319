






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class GameUIContainer extends Vue {
    @Prop({ default: false })
    isActive!: boolean;
}
