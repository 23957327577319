






import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
@Component({})
export default class PaytableImage extends Vue {
    @Prop({ default: '' })
    image?: string;
}
