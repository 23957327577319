


















































import { Component, Vue } from 'vue-property-decorator';

import PaytableSymbolDesktop from './../PaytableSymbolDesktop.vue';

@Component({
    components: {
        PaytableSymbolDesktop
    }
})
export default class SymbolsSection extends Vue {
    currenciesBigMultiplier: string[] = ['ALL', 'AMD', 'DOP', 'KZT', 'HUF', 'CLP', 'IRR', 'PKR', 'XAF', 'XOF', 'UYU', 'RSD', 'BYR', 'COP', 'KGS', 'TWD', 'IDR', 'IQD', 'KRW', 'MMK', 'MNT', 'PYG', 'RWF', 'TZS', 'UGX', 'UZS', 'VND'];

    getSymbolPays (symbol: string) {
        return this.$store.getters['paytable/getSymbolPays'](symbol);
    }
    get isCurrencyBigMultiplier (): boolean {
        return this.currenciesBigMultiplier.includes(this.currency);
    }
    get currency (): string {
        return this.$store.state.locale.currency;
    }
}
