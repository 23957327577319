




import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class Paytable extends Vue {
    @Prop({ default: 'Paytable Title Here' })
    title?: string;
}
