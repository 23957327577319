









import { Component, Vue, Prop } from 'vue-property-decorator';
import { EventBus } from '../../utils/EventBus';

@Component({})
export default class LoadingScreen extends Vue {
    @Prop({ default: 1 })
    progressPercent!: number;

    sourceProgressBarInside: string = `./assets/common/loading/loading_base_empty.png`;
    sourceProgressBarFull: string = `./assets/common/loading/loading_bar.png`;
}
