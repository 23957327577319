






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class GameUIContainer extends Vue {
    @Prop({ default: false })
    disabled!: boolean;

    @Prop({ default: false })
    active!: boolean;

    mounted () {
        this.$nextTick(() => {
            this.initEvents();
        });
    }

    initEvents () {
        const isTouchDevice = 'ontouchstart' in document.documentElement;

        if (isTouchDevice) {
            this.$el.addEventListener('touchstart', this.handleTouchStart.bind(this));
            this.$el.addEventListener('touchend', this.handleTouchEnd.bind(this));
        } else {
            this.$el.addEventListener('mousedown', this.handleMouseDownEvent.bind(this));
            this.$el.addEventListener('mouseup', this.handleMouseUpEvent.bind(this));
            this.$el.addEventListener('mouseleave', this.handleMouseUpEvent.bind(this));
        }
    }

    handleTouchStart () {
        this.$el.classList.add('down');
    }

    handleTouchEnd () {
        this.$el.classList.remove('down');
    }

    handleMouseDownEvent () {
        this.$el.classList.add('down');
    }

    handleMouseUpEvent () {
        this.$el.classList.remove('down');
    }

    handlePrimaryButtonClick () {
        !this.disabled && this.$emit('onButtonClick');
    }
}
