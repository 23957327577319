









import { Component, Prop, Mixins, Vue } from 'vue-property-decorator';

@Component({})
export default class PaytableDesktop extends Vue {
    @Prop({ default: '' })
    title!: string;
}
