








import { Component, Vue } from 'vue-property-decorator';
import GameBackground from './GameBackground.vue';
import GameContainer from './GameContainer.vue';
import DeviceOrientationMessage from './../shared/DeviceOrientationMessage/DeviceOrientationMessage.vue';

@Component({
    components: {
        GameBackground,
        GameContainer,
        DeviceOrientationMessage
    }
})
export default class GameUI extends Vue {
    // sourceBackground: string = `./assets/common/bgr.jpg`;

    get canShowDeviceOrientationMessage () {
        return this.$store.state.canShowDeviceOrientationMessage;
    }
    get isIOSChrome () {
        return window.deviceInfo.isIosChrome;
    }
}
