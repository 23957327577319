


























import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class BuyFeature extends Vue {
    private bfBuyTop: string = this.lang === 'en_US' || this.lang === 'en_GB' ? '167px' : '174px';
    private bfBuyLeft: string = this.lang === 'en_US' || this.lang === 'en_GB' ? '523px' : '493px';
    private bfBetPerSpinLeft: string = this.lang === 'en_US' || this.lang === 'en_GB' ? '189px' : '209px';
    private bfTotalBetLeft: string = this.lang === 'en_US' || this.lang === 'en_GB' ? '400px' : '427px';
    private paytableBfPrice: string = window.rtp !== 95 ? `./assets/${window.lang}/paytable/texts/paytable_page2_message_4_${window.rtp}.png` : `./assets/${window.lang}/paytable/texts/paytable_page2_message_4.png`;
    private paytableBfRTP: string = window.rtp !== 95 ? `./assets/${window.lang}/paytable/texts/paytable_page2_message_3_${window.rtp}.png` : `./assets/${window.lang}/paytable/texts/paytable_page2_message_3.png`;

    private bfBuyBtnLeft: string = this.lang === 'sv_SE' || this.lang === 'da_DK' ? '300px' : '364px';

    get lang (): string {
        return window.lang;
    }
}
