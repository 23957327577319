


























import { Component, Prop, Mixins, Vue } from 'vue-property-decorator';

@Component({})
export default class BuyFeatureSection extends Vue {
    private bfBuyTop: string = this.lang === 'en_US' || this.lang === 'en_GB' ? '72.5%' : '74%';
    private bfBuyLeft: string = this.lang === 'en_US' || this.lang === 'en_GB' ? '37%' : '30%';
    private bfBuyBtnLeft: string = this.lang === 'sv_SE' || this.lang === 'da_DK' ? '-7%' : '5%';
    private paytableBfPrice: string = window.rtp !== 95 ? `./assets/${window.lang}/paytable/texts/paytable_page2_message_4_${window.rtp}.png` : `./assets/${window.lang}/paytable/texts/paytable_page2_message_4.png`;
    private paytableBfRTP: string = window.rtp !== 95 ? `./assets/${window.lang}/paytable/texts/paytable_page2_message_3_${window.rtp}.png` : `./assets/${window.lang}/paytable/texts/paytable_page2_message_3.png`;

    get lang (): string {
        return window.lang;
    }
}
