




import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    }
})
export default class Clock extends Vue {
    dateLocale: string = '';

    mounted () {
        this.$nextTick(() => {
            setInterval(this.timer, 1000);
        });
    }

    timer () {
        const date = new Date();
        this.dateLocale = date.toLocaleTimeString();
    }
}
