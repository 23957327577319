





import { Component, Vue, Watch } from 'vue-property-decorator';

import { EventBus } from './../../../utils/EventBus';
import { EVENTS } from '../../../config/constants';

@Component({
    components: {}
})
export default class History extends Vue {
    mounted () {
        this.$nextTick(() => {
            EventBus.$on(EVENTS.HISTORY_RECEIVED, ({ value }: { value: string }) => {
                (this.$refs.history as HTMLElement) && ((this.$refs.history as HTMLElement).innerHTML = value);
            });
        });
    }
}
